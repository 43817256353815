.index-hero {
    background: url('#{$imagesPath}/home/hero-dim.jpg');
    background-position: center 0;
    background-size: cover;
    background-attachment: fixed;
    height: 590px;
    padding-top: 20px;
    overflow: hidden;
    position: relative;

    @media(max-width: 1024px) {
        background-attachment: inherit;
    }

    @media(max-width: 767px) {
        text-align: center;
        padding-top: 10px;
        height: inherit;
    }

    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(26, 35, 62, 0.58);
    }

    .container {
        position: relative;
        z-index: 10;
    }

    h2 {
        color: #fff;
        margin-top: 40px;
        font-size: 44px;
        font-weight: 200;
        line-height: 54px;
        text-shadow: 0 2px 1px rgba(0, 0, 0, 0.11);

        @include animationDelay(.1s);

        @media(max-width: 991px) {
            font-size: 38px;
            line-height: 52px;
        }

        @media(max-width: 767px) {
            width: auto;
        }
    }

    p, ul {
        color: #DFEDFF;
        font-weight: 400;
        margin-top: 30px;
        font-size: 17px;
        line-height: 27px;

        @include animationDelay(.5s);

        @media(max-width: 767px) {
            font-size: small;
            text-align: left;
        }
    }

    .btn-pill {
        margin-top: 30px;
        font-weight: 600;
        padding: 13px 44px 16px;

        &:active {
            border-color: #3b6b23;
        }

        @include animationDelay(.5s);
    }
}

.index-news {
    text-align: center;
    padding: 25px 0;
    background: #F9F9F9;
    border-bottom: 1px solid #DADADA;
    color: #6E6E6E;
    font-size: 15px;

    span {
        display: inline-block;
        color: #fff;
        text-transform: uppercase;
        letter-spacing: 1px;
        background: #8e82de;
        font-weight: 700;
        padding: 2px 7px;
        border-radius: 10px;
        font-size: 11px;
        position: relative;
        top: -1px;
        margin-right: 5px;
    }

    strong {
        font-weight: 600;
        color: #333;
        margin-right: 10px;
    }

    a {
        color: #0C80E4;
        margin-left: 5px;

        @media(max-width: 991px) {
            display: block;
            margin-top: 7px;
        }
    }
}